import * as monaco from "monaco-editor";
import monarch from "./monarch";
import conf from "./conf";
import completion from "./completion";

export const LANG_ID = "formula";

monaco.languages.register({ id: LANG_ID });

monaco.languages.setMonarchTokensProvider(LANG_ID, monarch);

monaco.languages.setLanguageConfiguration(LANG_ID, conf);

monaco.languages.registerCompletionItemProvider(LANG_ID, completion);
