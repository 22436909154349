import * as monaco from "monaco-editor";
import doc from "../doc";
import monarch from "./monarch";
import refcolumn from "../doc/group-refcolumn";

const CompletionItemKind = monaco.languages.CompletionItemKind;

export default {
  triggerCharacters: [" ", "{"],
  async provideCompletionItems() {
    let suggestions = [];
    suggestions = suggestions.concat(
      monarch.keywords.map(item => {
        return {
          label: item,
          kind: CompletionItemKind.Keyword,
          sortText: "4",
          detail: "Keyword",
          insertText: item
        };
      }),
      doc.getSupportedFunctions().map(item => {
        return {
          label: item.name,
          kind: CompletionItemKind.Function,
          sortText: "3",
          detail: item.returns,
          insertText: item.snippet || `${item.name}($1)$0`,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
        };
      }),
      refcolumn.children.map(frm => {
        return {
          label: frm.name,
          detail: `{${frm.alias}}`,
          kind: CompletionItemKind.Field,
          sortText: "1",
          preselect: true,
          insertText: frm.alias || frm.name
        };
      })
    );
    return { suggestions };
  }
};
