<template>
  <div class="formula-editor">
    <!-- monaco-editor 没有 placeholder 功能，因此自定义 -->
    <span v-show="!value" class="placeholder">请输入公式...</span>
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
import debounce from "lodash/debounce";
import doc from "./doc";
import { LANG_ID } from "./language";
import * as utils from "./utils";

export default {
  props: {
    value: String
  },
  mounted() {
    const editor = monaco.editor.create(this.$el, {
      value: this.value,
      language: LANG_ID,
      lineNumbers: "off",
      lineHeight: 24,
      renderLineHighlight: false,
      tabSize: 2,
      fontSize: 13,
      minimap: {
        enabled: false
      },
      padding: {
        top: 6
      },
      glyphMargin: false,
      folding: false,
      scrollbar: {
        verticalScrollbarSize: 6,
        verticalSliderSize: 6,
        horizontalScrollbarSize: 6,
        horizontalSliderSize: 6
      }
    });
    // 监听内容变化
    editor.onDidChangeModelContent(() => {
      this.$emit("input", editor.getValue());
    });
    // 移动光标时显示对应文档
    editor.onDidChangeCursorPosition(debounce(this.onCursorMoved, 200));
    // 在文档中选择公式时，将公式插入到编辑器
    doc.on("select", this.onSelectFormula);
    // resize 时重新 layout 并 auto-focus
    const callback = debounce(function() {
      editor.layout();
      editor.focus();
    }, 300);
    const observer = new ResizeObserver(callback);
    observer.observe(this.$el);
    this.editor = editor;
  },
  destroyed() {
    this.editor.dispose();
    doc.off();
    this.editor = null;
  },
  methods: {
    onCursorMoved(evt) {
      const token = utils.getTokenAtPosition(this.editor, evt.position);
      if (token) {
        doc.emit("focus", token);
      }
    },
    /**
     * @param {import("./doc/model").Formula} formula
     */
    onSelectFormula(formula) {
      utils.insertSnippet(
        this.editor,
        formula.isFunction ? `${formula.name}(` : formula.snippet,
        formula.isFunction ? ")" : ""
      );
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.formula-editor {
  height: 100%;
  position: relative;
  /deep/ .editor-scrollable {
    border-top-right-radius: 4px;
  }
  .placeholder {
    position: absolute;
    left: 10px;
    top: 6px;
    font-size: 13px;
    line-height: 24px;
    color: fade(black, 45%);
    z-index: 1;
    pointer-events: none;
  }
}
</style>
