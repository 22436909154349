import * as monaco from "monaco-editor";
import { LANG_ID } from "./language";

/**
 * @param {monaco.editor.IStandaloneCodeEditor} editor
 * @param {monaco.Position} position
 */
export function getTokenAtPosition(editor, position) {
  if (!editor) {
    return;
  }
  const lines = monaco.editor.tokenize(editor.getValue(), LANG_ID);
  const tokens = lines[position.lineNumber - 1] || [];
  const cursorColumn = position.column;
  for (let i = 0; i < tokens.length; i += 1) {
    const start = tokens[i].offset + 1;
    const end = tokens[i + 1] ? tokens[i + 1].offset + 1 : Infinity;
    if (!(cursorColumn >= start && cursorColumn <= end)) {
      continue;
    }
    const tokenType = tokens[i].type;
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: start,
      endColumn: end
    };
    if (tokenType === "predefined.sql.formula") {
      const value = editor.getModel().getValueInRange(range);
      return { value, type: "function" };
    }
    if (tokenType === "tag.formula") {
      const value = editor.getModel().getValueInRange(range);
      return { value: value.replace(/\{|\}/g, ""), type: "ref" };
    }
  }
  return null;
}

/**
 * 插入代码，并使光标停留在 text1 和 text2 之间
 * @param {monaco.editor.IStandaloneCodeEditor} editor editor 实例
 * @param {string} text1
 * @param {string} text2
 */
export function insertSnippet(editor, text1, text2) {
  if (!editor) {
    return;
  }
  editor.executeEdits(
    "doc-select",
    editor.getSelections().map(range => ({
      range,
      id: { major: 1, minor: 1 },
      text: text1,
      forceMoveMarkers: true
    }))
  );
  if (!text2) {
    return;
  }
  const selections = editor.getSelections();
  editor.executeEdits(
    "doc-select",
    selections.map(range => ({
      range,
      id: { major: 1, minor: 1 },
      text: text2,
      forceMoveMarkers: true
    })),
    selections
  );
}
