import doc from "../doc";

export default {
  defaultToken: "",
  // tokenPostfix: ".frm",
  ignoreCase: true,
  brackets: [
    { open: "[", close: "]", token: "delimiter.square" },
    { open: "(", close: ")", token: "delimiter.parenthesis" }
  ],
  keywords: [
    "ANY",
    "BINARY",
    "BOTH",
    "CASE",
    "CAST",
    "CURRENT_DATE",
    "CURRENT_TIME",
    "CURRENT_TIMESTAMP",
    "DEFAULT",
    "DISTINCT",
    "ELSE",
    "END",
    "FALSE",
    "FOR",
    "FROM",
    "ISNULL",
    "NOTNULL",
    "THEN",
    "TRUE",
    "WHEN",
    "WITH"
  ],
  operators: ["AND", "BETWEEN", "IN", "NOT", "OR", "IS", "NULL", "INTERSECT"],
  builtinFunctions: doc.getSupportedFunctions().map(formula => formula.name),
  builtinVariables: [
    // NOT SUPPORTED
  ],
  pseudoColumns: [
    // NOT SUPPORTED
  ],
  /**
   * 可在 editor._themeService._desiredTheme.themeData 属性查看定义的 token styles
   */
  tokenizer: {
    root: [
      { include: "@whitespace" },
      { include: "@numbers" },
      { include: "@strings" },
      { include: "@complexIdentifiers" },
      { include: "@scopes" },
      // 引用列，如 {A}
      [/\{[_a-zA-Z\u4e00-\u9fa5]+\}/, "tag"],
      [/[;,.]/, "delimiter"],
      [/[()]/, "@brackets"],
      [
        /[\w@]+/,
        {
          cases: {
            "@operators": "operator.swift",
            "@builtinVariables": "predefined.sql",
            "@builtinFunctions": "predefined.sql",
            "@keywords": "keyword",
            "@default": "identifier"
          }
        }
      ],
      [/[<>=!%&+\-*/|~^]/, "operator.swift"]
    ],
    whitespace: [[/\s+/, "white"]],
    numbers: [
      [/0[xX][0-9a-fA-F]*/, "number"],
      [/[$][+-]*\d*(\.\d*)?/, "number"],
      [/((\d+(\.\d*)?)|(\.\d+))([eE][-+]?\d+)?/, "number"]
    ],
    strings: [
      [/'/, { token: "string.sql", next: "@string" }],
      [/"/, { token: "string.sql", next: "@stringDouble" }]
    ],
    string: [
      [/[^']+/, "string.sql"],
      [/''/, "string.sql"],
      [/'/, { token: "string.sql", next: "@pop" }]
    ],
    stringDouble: [
      [/[^"]+/, "string.sql"],
      [/""/, "string.sql"],
      [/"/, { token: "string.sql", next: "@pop" }]
    ],
    complexIdentifiers: [
      [/`/, { token: "annotation", next: "@quotedIdentifier" }]
    ],
    quotedIdentifier: [
      [/[^`]+/, "annotation"],
      [/``/, "annotation"],
      [/`/, { token: "annotation", next: "@pop" }]
    ],
    scopes: [
      // NOT SUPPORTED
    ]
  }
};
